<template>
  <div class="popup-image">
    <UiImg
      :image="image"
      class="popup-image__min"
      use-image-size
      @click="togglePopupVisibility"
    />

    <div
      class="popup-image__window"
      :class="{ 'popup-image__window--open': isPopupVisible }"
      @click="togglePopupVisibility"
    >
      <UiImg
        :image="image"
        use-image-size
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { clearAllBodyScrollLocks, toggleBodyScrollLock } from 'body-scroll-lock-enhanced'
import { computed, onUnmounted, ref, watch } from 'vue'

import { UiImg } from '~ui/components'
import useFlatGallery from '~ui/composables/useFlatGallery'
import type { UiCeGalleryProps } from '~ui/types'

const props = defineProps<UiCeGalleryProps>()

const isPopupVisible = ref(false)

const image = computed(() => useFlatGallery(props).value?.[0])

watch(isPopupVisible, (state) => {
  toggleBodyScrollLock(document.body, {
    toggleValue: state,
    reserveScrollbarGutter: true
  })
})

onUnmounted(clearAllBodyScrollLocks)

const togglePopupVisibility = () => {
  isPopupVisible.value = !isPopupVisible.value
}
</script>

<style lang="scss">
.popup-image {
  text-align: center;

  &__min {
    cursor: pointer;
    width: auto;
    height: auto;
    max-width: 100%;
  }

  &__window {
    cursor: pointer;
    position: fixed;
    display: none;
    align-items: center;
    justify-content: center;
    inset: 0;
    z-index: z-index(popup);
    padding: spacing(sm);
    max-height: 100vh;
    opacity: 0;

    @include media-query(lg) {
      padding: spacing(lg);
    }

    &--open {
      backdrop-filter: blur(10px);
      display: flex;
      opacity: 1;
      background-color: hsl(0 0% 0% / 50%);

      & img {
        height: auto;
        width: auto;
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
}
</style>
